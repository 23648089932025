import { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent, {
  Cookies,
  resetCookieConsentValue,
  getCookieConsentValue,
} from "react-cookie-consent";
import { Accordion } from "react-bootstrap";
import ReactGA from "react-ga";

//Import Parcials
import Header from "./partials/header";
import Footer from "./partials/footer";

//Import the pages
import Home from "./pages/home";
import Esofago from "./pages/esofago";
import Esoxx from "./pages/esoxx";
import ConselhosPraticos from "./pages/conselhos-praticos";
import RefluxoGastroesofagico from "./pages/refluxo-gastroesofagico";
import PoliticaCookies from "./pages/politica_cookies";
import "./App.css";

const TRACKING_ID = "UA-21976295-4"; // OUR_TRACKING_ID

const public_folder = process.env.PUBLIC_URL;

function closeOverlay() {
  document.getElementById("overlay").style.display = "none";
  document.getElementById("bibliografia_in_overlay").style.display = "none";
}

const App = () => {
  if (getCookieConsentValue()) {
    ReactGA.initialize(TRACKING_ID);
  }

  useEffect(() => {
    if (getCookieConsentValue()) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="politica-cookies" element={<PoliticaCookies />} />
          <Route exact path="o-esofago" element={<Esofago />} />
          <Route exact path="o-que-e-esoxx" element={<Esoxx />} />
          <Route
            exact
            path="conselhos-praticos"
            element={<ConselhosPraticos />}
          />
          <Route
            exact
            path="refluxo-gastroesofagico"
            element={<RefluxoGastroesofagico />}
          />
        </Routes>
      </Router>
      <Footer />
      <div id="overlay" className="overlay">
        <div className="bibliografia" id="bibliografia_in_overlay">
          <a className="closebtn" onClick={closeOverlay}>
            <img
              className=""
              src={public_folder + "images/close.png"}
              alt="close overlay"
            />
          </a>
          <h3>REFERÊNCIAS BIBLIOGRÁFICAS</h3>
          <p>
            1.{" "}
            <a href="https://saudedigestiva.pt/o/">
              https://saudedigestiva.pt/o/
            </a>
            , acedido em maio 2021.
            <br />
            2.{" "}
            <a href="https://www.spg.pt/clinicos/nocs/">
              https://www.spg.pt/clinicos/nocs/
            </a>
            , acedido em março 2021.
            <br />
            3. Rede Nacional de Especialidade Hospitalar e de Referenciação,
            Gasterentologia e Hepatologia, 15 de novembro 2017 - link:
            <a href="https://www.sns.gov.pt/wp-content/uploads/2018/01/RNEHR-Gastrenterologia-e-Hepatologia_Aprovada-10-01-2018.pdf">
              https://www.sns.gov.pt/wp-content/uploads/2018/01/RNEHR-Gastrenterologia-e-Hepatologia_Aprovada-10-01-2018.pdf
            </a>
            , acedido em março 2021.
            <br />
            4. Wahlqvist P, et al. A. Systematic review: The impact of
            gastro-oesophageal reflux disease on work productivity. Aliment
            Pharmacol Ther 2006; 24:259-72.
            <br />
            5. Yang XJ, et al. Anxiety and depression in patients with
            gastroesophageal reflux disease and their effect on quality of life.
            World J Gastroenterol 2015, 21:4302-9.
            <br />
            6. Kulig M, et al. Quality of life in relation to symptoms in
            patients with gastro-oesophageal reflux disease — an analysis based
            on the ProGERD initiative. Aliment Pharmacol Ther 2003; 18: 767-776.
            <br />
            7.{" "}
            <a href="https://www.spg.pt/publico/gastrenterologia-saiba-mais/">
              https://www.spg.pt/publico/gastrenterologia-saiba-mais/
            </a>
            , acedido em março 2021.
            <br />
            8. Folheto informatido de ESOXX<sup>®</sup> ONE - link:
            <a href="https://pt.alfasigma.com/wp-content/uploads/2020/10/ESOXX_2020.pdf">
              https://pt.alfasigma.com/wp-content/uploads/2020/10/ESOXX_2020.pdf
            </a>
            , acedido em março 2021.
            <br />
            9. Palmieri B, et al. Eur Rev Med Parmacol Sci 2013;17:3272-8.
            <br />
            10. Savarino V, et al. Randomised clinical trial: mucosal protection
            combined with acid suppression in the treatment of non-erosive
            reflux disease - efficacy of Esoxx, a hyaluronic acid-chondroitin
            sulphate based bioadhesive formulation. Aliment Pharmacol Ther 2017;
            45: 631-642.
            <br />
            11. Amaral D.M., Gastroesophageal Reflux in the Pediatric Age -
            Review Paper. 2012 universidade do Porto.
            <br />
            12. Festi D, et al. Body weight, lifestyle, dietary habits and
            gastroesophageal reflux disease. World J Gastroenterol 2009 April
            14;15(14):1690-1701.
            <br />
            13. Jung H, et al. Gastroesophageal Reflux Disease and Sleep
            Disorders: Evidence for a Causal Link and Therapeutic Implications.
            J Neurogastroenterol Motil, Vol. 16 No. 1 January, 2010.
            <br />
            14.{" "}
            <a href="http://www.healthline.com/health/gerd/diet-nutrition">
              http://www.healthline.com/health/gerd/diet-nutrition
            </a>
            , acedido em maio 2021.
            <br />
            15. Instruções de utilização Esoxx<sup>®</sup> One;
          </p>
        </div>
      </div>
      <CookieConsent
        overlay="true"
        style={{
          background: "#A3258E",
          maxWidth: "80%",
          margin: "0 auto",
          position: "absolute",
          left: "50%",
          top: "15%",
          transform: "translate(-50%, -10%)",
          flexDirection: "column",
          padding: "40px 0",
          bottom: "unset !important",
        }}
        buttonText="Aceitar todos os cookies e fechar"
        buttonStyle={{
          color: "#4e503b",
          fontSize: "15px",
          background: "white",
          padding: "13px",
        }}
        declineButtonStyle={{
          color: "#4e503b",
          fontSize: "15px",
          background: "white",
          padding: "13px",
        }}
        expires={365}
        enableDeclineButton
        declineButtonText="Recusar Cookies analíticos"
      >
        <h2>Definições de Cookies</h2>
        <p>
          Os cookies consistem em partes de código instaladas no interior do
          navegador de internet (browser) que podem ser armazenadas no seu
          computador e que ajudam o proprietário do site a prestar o serviço de
          acordo com os fins descritos.
        </p>
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Cookies estritamente necessários
            </Accordion.Header>
            <Accordion.Body>
              Alguns cookies são essenciais para aceder a áreas específicas do
              nosso site. Permitem a navegação no site e a utilização das suas
              aplicações. Sem estes cookies, os serviços que o exijam não podem
              ser prestados.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Cookies analíticos</Accordion.Header>
            <Accordion.Body>
              Utilizamos estes cookies para analisar a forma como os
              utilizadores usam o site e monitorar a performance deste. Isto
              permite-nos fornecer uma experiência de alta qualidade ao
              personalizar a nossa oferta e rapidamente identificar e corrigir
              quaisquer problemas que surjam. Por exemplo, usamos cookies de
              desempenho para saber quais as páginas mais populares, qual o
              método de ligação entre páginas que é mais eficaz, ou para
              determinar a razão de algumas páginas estarem a receber mensagens
              de erro. Baseado na utilização do site, podemos também utilizar
              estes cookies para destacar artigos ou serviços do site que
              pensamos ser do interesse dos utilizadores. Estes cookies são
              utilizados apenas para efeitos de criação e análise estatística,
              sem nunca recolher informação de carácter pessoal.
              <h3
                style={{
                  fontSize: "20px",
                  marginTop: "50px",
                }}
              >
                Soluções Afectas:
              </h3>
              Google Analytics
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </CookieConsent>
    </div>
  );
};

export default App;
