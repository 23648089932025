import { React, Component } from "react";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./esoxx.css";
import $ from "jquery";
window.jQuery = window.$ = $;

const public_folder = process.env.PUBLIC_URL;

class Esoxx extends Component {
  openVideo() {
    document.getElementById("overlay").style.display = "block";
    document.getElementById("video").style.display = "block";
    document.getElementById("esoxx_video").play();
  }

  closeVideo() {
    document.getElementById("overlay").style.display = "none";
    document.getElementById("video").style.display = "none";
    document.getElementById("esoxx_video").pause();
  }

  render() {
    return (
      <div id="esoxx-container">
        <div className="purple-bar">
          <h1 style={{ fontWeight: "bold" }}>
            ESOXX
            <sup>
              <small>&reg;</small>
            </sup>{" "}
            ONE
          </h1>
        </div>
        <section id="esoxx-area1">
          <Container className="">
            <Row>
              <Col lg={6}>
                <h2>
                  O QUE É ESOXX
                  <sup>
                    <small>&reg;</small>
                  </sup>{" "}
                  ONE?
                </h2>
                <h3>
                  SOLUÇÃO COMPLEMENTAR, ÚNICA E INOVADORA NO TRATAMENTO DA DRGE
                </h3>
                <p>
                  ESOXX<sup>®</sup> ONE é um dispositivo médico de fórmula
                  patenteada, disponível sob a forma de solução oral (saquetas
                  monodose) e que contém Ácido hialurónico, Sulfato de
                  condroitina e um agente gelificante termorreversível, o
                  Poloxâmero 407.
                  <sup>8</sup>
                </p>
                <p className="purple-txt">
                  A ação mecânica de ESOXX<sup>®</sup> ONE cria um efeito de
                  barreira capaz de promover o alívio rápido dos sintomas
                  relacionados com o refluxo gastroesofágico.<sup>9</sup>
                </p>
              </Col>
              <Col lg={6}>
                <img src={public_folder + "/images/esoxx_box.png"} alt="" />
                <Row>
                  <Col lg={6}>
                    <p>
                      Deverá ler cuidadosamente a rotulagem e as instruções de
                      utilização
                    </p>
                  </Col>
                  <Col
                    lg={6}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <a
                      href={public_folder + "Instrucoes_utilizacao_Esoxx.pdf"}
                      target="_blank"
                    >
                      <img
                        src={public_folder + "/images/pdf_export_esoxx.png"}
                        alt=""
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="purple-bar">
          <h1>
            A BARREIRA CONTRA O <strong>REFLUXO</strong> GASTROESOFÁGICO
          </h1>
        </div>
        <section
          id="esoxx-area2"
          style={{
            backgroundImage: `url(${public_folder}/images/exoss-video-bg.jpg)`,
          }}
        >
          <Container>
            <Row>
              <Col>
                <h2>MECANISMO DE AÇÃO</h2>
                <h3 className="accoes accao1">
                  PROTEGE
                  <span>SULFATO DE CONDROITINA (SC)</span>
                </h3>
                <h3 className="accoes accao2">
                  REPARA
                  <span>ÁCIDO HIALURÓNICO (AH)</span>
                </h3>
                <h3 className="accoes accao3">
                  PROLONGA
                  <span>POLOXÂMERO 407</span>
                </h3>
                <div id="video-link">
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>Ver video</Tooltip>}
                    placement="bottom"
                  >
                    <img
                      onClick={this.openVideo}
                      src={public_folder + "/images/video-link.png"}
                      alt="ver video"
                    />
                  </OverlayTrigger>
                  ,
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="purple-bar">
          <h1>
            O <strong>REFLUXO</strong> AQUI <strong>NÃO PASSA</strong>
          </h1>
        </div>
        <section id="esoxx-area3">
          <Container>
            <Row>
              <Col>
                <h2>
                  SOLUÇÃO COMPLEMENTAR, ÚNICA E INOVADORA NO TRATAMENTO DA DRGE
                </h2>
              </Col>
            </Row>
            <Row className="spacer">
              <Col>
                <img src={public_folder + "/images/eficaz.png"} alt="" />
              </Col>
              <Col>
                <img src={public_folder + "/images/rapido.png"} alt="" />
              </Col>
              <Col>
                <img src={public_folder + "/images/duradouro.png"} alt="" />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <img src={public_folder + "/images/esoxx-protege.jpg"} alt="" />
              </Col>
              <Col lg={8}>
                <h3>
                  PROTEGE E PROMOVE A CICATRIZAÇÃO DE LESÕES DO ESÓFAGO
                  <sup>10</sup>
                </h3>
                <ul>
                  <li
                    style={{
                      backgroundImage: `url(${public_folder}/images/list-icon.png)`,
                    }}
                  >
                    Disfonia<sup>15</sup>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${public_folder}/images/list-icon.png)`,
                    }}
                  >
                    Azia<sup>15</sup>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${public_folder}/images/list-icon.png)`,
                    }}
                  >
                    Tosse irritativa<sup>15</sup>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${public_folder}/images/list-icon.png)`,
                    }}
                  >
                    Refluxo ácido<sup>15</sup>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>CONTRAINDICAÇÕES</h4>
                <p>
                  Não existem contraindicações particulares, com exceção de
                  casos de hipersensibilidade a qualquer dos componentes do
                  produto.
                  <br /> Em caso de efeitos indesejáveis suspenda o tratamento e
                  contacte o seu médico.
                </p>

                <p>
                  Apesar de não serem conhecidos efeitos indesejáveis,
                  aconselha-se a não utilização do produto durante a gravidez ou
                  nos primeiros meses de amamentação. Em qualquer dos casos é
                  aconselhável que consulte previamente o seu médico. Não são
                  conhecidas outras interações com outros produtos de
                  administração oral.
                </p>

                <h4>ADVERTÊNCIAS</h4>
                <p>
                  Não deverá exceder a dose recomendada.
                  <br />
                  Se os sintomas persistirem consulte o seu médico.
                  <br />
                  Não estão disponíveis dados clínicos em crianças com idade
                  inferior a 12 anos, pelo que não deve ser administrado a
                  crianças com menos de 12 anos de idade.
                  <br />
                  Não utilize o produto para além da data de validade impressa
                  na embalagem.
                  <br />
                  Guarde em local fresco e seco, longe de fontes de calor.
                  <br />
                  Não congele.
                  <br />
                  Manter fora do alcance das crianças.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <div id="video">
          <img
            onClick={this.closeVideo}
            className="closebtn"
            src={public_folder + "/images/close.png"}
          />
          <video id="esoxx_video" width="550" height="340" controls>
            <source
              src={public_folder + "/Resumo_MoA_ESOXX_One.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default Esoxx;
