import React from "react";
import { Component } from "react";
import "./footer.css";
import { Container, Row, Col } from "react-bootstrap";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
const public_folder = process.env.PUBLIC_URL;

function openBibliografia() {
  document.getElementById("overlay").style.display = "block";
  document.getElementById("bibliografia_in_overlay").style.display = "block";
}
function reset_cookies() {
  resetCookieConsentValue();
  window.location.reload();
  return false;
}

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="ref_bibliograficas">
          <Container>
            <Row>
              <Col>
                <div className="ref_bibliograficas_holder">
                  REFERÊNCIAS BIBLIOGRÁFICAS
                  <a
                    className="plus_icon"
                    id="open_bibliografia"
                    onClick={openBibliografia}
                  >
                    <img src={public_folder + "/images/plus.png"} alt="" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <footer>
          <Container>
            <Row>
              <Col md={4}>
                <img src={public_folder + "/images/logo_footer.png"} alt="" />
                <p>
                  ALFASIGMA PORTUGAL, LDA.
                  <br />
                  Avenida José Malhoa, nº 2, Ed. Malhoa Plaza, Escritório 2.2
                  1070-325 Lisboa, Portugal
                </p>
                <p>Para mais informações deverá contactar a Alfasigma.</p>
                <p>Tel. 217 226 110 (Chamada para rede fixa nacional)</p>
              </Col>
              <Col md={4}>
                <nav>
                  <ul>
                    <li>
                      <a
                        href="https://pt.alfasigma.com/politica-de-privacidade/"
                        target="_blank"
                      >
                        Política de Privacidade
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="/politica-cookies">Política de Cookies</a>
                    </li>
                    <li>
                      <a id="reset_cookies" onClick={reset_cookies}>
                        Definições de Cookies
                      </a>
                    </li>
                  </ul>
                </nav>
                <p className="signiture">
                  ©Alfasigma 2021. Todos os direitos reservados.
                </p>
              </Col>
              <Col md={4}>
                <img
                  src={public_folder + "/images/logo_footer_right.png"}
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
