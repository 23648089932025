import { React, Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./conselhos-praticos.css";
import $ from "jquery";
window.jQuery = window.$ = $;

const public_folder = process.env.PUBLIC_URL;

class ConselhosPatricos extends Component {
  closeOverlay() {
    $("#overlay-conselho").hide();
    $("#sono").hide();
    $("#exercicio").hide();
    $("#alimentacao").hide();
  }
  openAdviceAlim() {
    $("#overlay-conselho").show();
    $("#alimentacao").show();
    $("#exercicio").hide();
    $("#sono").hide();
  }
  openAdviceExerc() {
    $("#overlay-conselho").show();
    $("#exercicio").show();
    $("#alimentacao").hide();
    $("#sono").hide();
  }
  openAdviceSono() {
    $("#overlay-conselho").show();
    $("#sono").show();
    $("#exercicio").hide();
    $("#alimentacao").hide();
  }
  render() {
    return (
      <div>
        <div
          id="conselhos-praticos"
          style={{
            backgroundImage: `url(${public_folder}/images/conselho_pratico_bg.png)`,
          }}
        >
          <Container>
            <Row>
              <Col md={5}>
                <img
                  src={public_folder + "/images/conselho_pratico.png"}
                  alt=""
                />
              </Col>
              <Col md={7}>
                <h2>CONSELHOS PRÁTICOS</h2>
                <p>
                  FAÇA DOWNLOAD AQUI DE 3 GUIAS ÚTEIS SOBRE ALIMENTAÇÃO,
                  EXERCÍCIO FÍSICO E QUALIDADE DO SONO.
                </p>
                <ul className="conselhos_praticos">
                  <li>
                    <a className="open-advice" onClick={this.openAdviceAlim}>
                      <img
                        className="pdf_icon"
                        src={public_folder + "/images/pdf_icon.png"}
                        alt=""
                      />
                      ALIMENTAÇÃO
                    </a>
                  </li>
                  <li>
                    <a className="open-advice" onClick={this.openAdviceExerc}>
                      <img
                        className="pdf_icon"
                        src={public_folder + "/images/pdf_icon.png"}
                        alt=""
                      />
                      EXERCÍCIO FÍSICO
                    </a>
                  </li>
                  <li>
                    <a className="open-advice" onClick={this.openAdviceSono}>
                      <img
                        className="pdf_icon"
                        src={public_folder + "/images/pdf_icon.png"}
                        alt=""
                      />
                      QUALIDADE DO SONO
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="overlay-conselho">
          <div id="conselho">
            <a className="closebtn" onClick={this.closeOverlay}>
              <img
                className=""
                src={public_folder + "images/close_purple.png"}
                alt="close overlay"
              />
            </a>
            <div id="alimentacao">
              <Container>
                <Row>
                  <Col md={5}>
                    <img
                      src={public_folder + "/images/conselho_alimentacao.png"}
                      alt=""
                    />
                    <a
                      target="_blank"
                      href={public_folder + "/Folheto_1_DRGE_e_alimentação.pdf"}
                    >
                      <img
                        className="download_btn"
                        src={public_folder + "/images/download_btn.png"}
                        alt="download"
                      />
                    </a>
                  </Col>
                  <Col md={7}>
                    <img
                      src={
                        public_folder + "/images/title_alimentacao_details.png"
                      }
                      alt=""
                    />
                    <br />
                    <br />
                    <br />
                    <img
                      src={public_folder + "/images/details_alimentacao.png"}
                      alt="details_alimentacao"
                    />
                  </Col>
                  <p className="conselho_legend">Fonte: Adaptado de 11.</p>
                </Row>
              </Container>
            </div>
            <div id="exercicio">
              <Container>
                <Row>
                  <Col md={5}>
                    <img
                      src={public_folder + "/images/conselho_exercicio.png"}
                      alt=""
                    />
                    <a
                      target="_blank"
                      href={
                        public_folder + "/Folheto_2_DRGE_e_exercicio_fisico.pdf"
                      }
                    >
                      <img
                        className="download_btn"
                        src={public_folder + "/images/download_btn.png"}
                        alt="download"
                      />
                    </a>
                  </Col>
                  <Col md={7}>
                    <img
                      src={
                        public_folder + "/images/title_exercicio_details.png"
                      }
                      alt=""
                    />
                    <br />
                    <br />
                    <br />
                    <img
                      src={public_folder + "/images/details_exercicio.png"}
                      alt="details_exercicio"
                    />
                  </Col>
                </Row>
                <p className="conselho_legend">Fonte: Adaptado de 12.</p>
              </Container>
            </div>
            <div id="sono">
              <Container>
                <Row>
                  <Col md={5}>
                    <img
                      src={public_folder + "/images/conselho_sono.png"}
                      alt=""
                    />
                    <a
                      target="_blank"
                      href={
                        public_folder +
                        "/Folheto_3_DRGE_e_qualidade_do_sono.pdf"
                      }
                    >
                      <img
                        className="download_btn"
                        src={public_folder + "/images/download_btn.png"}
                        alt="download"
                      />
                    </a>
                  </Col>
                  <Col md={7}>
                    <img
                      src={public_folder + "/images/title_sono_details.png"}
                      alt=""
                    />
                    <br />
                    <br />
                    <br />
                    <img
                      src={public_folder + "/images/details_sono.png"}
                      alt="details_sono"
                    />
                  </Col>
                </Row>
                <p className="conselho_legend">Fonte: Adaptado de 13 e 14.</p>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConselhosPatricos;
