import { React, Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./refluxo-gastroesofagico.css";

const public_folder = process.env.PUBLIC_URL;

class RefluxoGastroesofagico extends Component {
  render() {
    return (
      <div>
        <div
          id="refluxo-gastroesofagico"
          style={{
            backgroundImage: `url(${public_folder}/images/refluxo-gastroesofagico.png)`,
          }}
        >
          <Container>
            <Row>
              <Col lg={5}>
                <img
                  src={
                    public_folder + "images/refluxo-gastroesofagico-graph.png"
                  }
                  alt=""
                />
              </Col>
              <Col lg={7}>
                <h1>REFLUXO GASTROESOFÁGICO</h1>
                <p>
                  Sabia que a DOENÇA DO REFLUXO GASTROESOFÁGICO (DRGE) é a
                  doença crónica mais comum do trato digestivo alto<sup>2</sup>
                </p>
                <p>
                  Na população PORTUGUESA com mais de 18 anos, a PREVALÊNCIA É
                  DE 35%.
                  <sup>3</sup>
                </p>

                <p>
                  A DRGE tem um impacto negativo ao nível do BEM-ESTAR e
                  QUALIDADE DE VIDA, afetando vários aspetos da vida diária:
                  PRODUTIVIDADE LABORAL, ANSIEDADE E DEPRESSÃO, INTERAÇÃO
                  SOCIAL, EXERCÍCIO FÍSICO.<sup>4,5,6</sup>
                </p>

                <p>
                  O refluxo gastroesofágico define-se como a passagem do
                  conteúdo gástrico para o esófago, na ausência de vómitos. É um
                  evento frequente que, na maioria dos adultos, ocorre
                  regularmente, a seguir às refeições, em pequena quantidade.
                  <sup>7</sup>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="refluxo-white-section">
          <Container>
            <Row>
              <Col>
                <h2>
                  O QUE É A DOENÇA DE REFLUXO GASTROESOFÁGICO?<sup>7</sup>
                </h2>
                <p>
                  Quando o refluxo gastroesofágico é causa de sintomas, como a
                  azia, ou de lesões no esófago, estamos em presença de uma
                  doença de refluxo gastroesofágico (DRGE).
                </p>
                <h3>CAUSAS DA DRGE</h3>
                <p>
                  A DRGE resulta de um desequilíbrio entre os fatores de defesa
                  e os fatores de agressão ao esófago. Nos fatores de agressão
                  incluem-se alguns alimentos como produtos derivados do tomate,
                  sumos de citrinos, chocolate, bebidas com cafeína, tabaco,
                  bebidas alcoólicas, alguns medicamentos (nitratos,
                  estrogénios, contracetivos orais, bloqueadores dos canais de
                  cálcio, alendronato, etc), conteúdo ácido do estômago, refluxo
                  biliar.
                </p>
                <h3>
                  SINTOMAS DA DRGE<sup>7</sup>
                </h3>
                <ul>
                  <li>
                    <span className="green_bold">Azia</span> - é a manifestação
                    mais frequente da DRGE, consistindo numa sensação de
                    queimadura no meio do peito, que pode irradiar em direção ao
                    pescoço, que aparece geralmente menos de uma hora após as
                    refeições e que se pode agravar na posição deitada ou
                    inclinada para a frente.
                  </li>
                  <li>
                    <span className="green_bold">Regurgitação</span> - sensação
                    de que os alimentos voltam à boca, sem esforço de vómito.
                  </li>
                  <li>
                    <span className="green_bold">Dor à deglutição</span> dos
                    alimentos.
                  </li>
                  <li>
                    <span className="green_bold">Dificuldade em deglutir</span>{" "}
                    os alimentos.
                  </li>
                  <li>
                    <span className="green_bold">Dor torácica</span> - dor na
                    região retrosternal, de origem não cardíaca.
                  </li>
                  <li>
                    <span className="green_bold">Outras</span> - tosse, falta de
                    ar, rouquidão, dor de ouvidos, gengivite, alteração do
                    esmalte dentário.
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="spacer">
              <Col lg={8}>
                <h3>
                  TRATAMENTO<sup>7</sup>
                </h3>
                <h4 style={{ fontWeight: "bold" }}>Medidas gerais:</h4>
                <ul>
                  <li>Recomendam-se refeições pequenas.</li>
                  <li>Evitar alguns alimentos:</li>- gorduras - chocolates -
                  citrinos - refogados à base de tomate - alimentos que causem
                  os sintomas.
                  <li>Evitar bebidas gaseificadas.</li>
                  <li>Evitar bebidas com cafeína.</li>
                  <li>Evitar comer nas 2 a 3 horas antes de deitar.</li>
                  <li>Não fumar.</li>
                  <li>Emagrecer.</li>
                  <li>Não usar roupa apertada.</li>
                  <li>
                    Evitar atividades que aumentem a pressão intra-abdominal
                    logo após as refeições (p. ex. jardinagem).
                  </li>
                  <li>
                    Elevar a cabeceira da cama cerca de 15 centímetros (colocar
                    um taco de madeira, p. ex., debaixo dos pés da cama, do lado
                    da cabeceira).
                  </li>
                </ul>
              </Col>
              <Col lg={4}>
                <img
                  src={
                    public_folder + "images/refluxo-gastroesofagico-graph_2.png"
                  }
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default RefluxoGastroesofagico;
