import { React, Component } from "react";
import { Carousel } from "react-bootstrap";
import "./home.css";

const public_folder = process.env.PUBLIC_URL;

class Home extends Component {
  render() {
    return (
      <Carousel pause="false" interval="2000" fade controls="false">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={public_folder + "images/slider_1.jpg"}
            alt="1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={public_folder + "images/slider_2.jpg"}
            alt="2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={public_folder + "images/slider_3.jpg"}
            alt="3"
          />
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Home;
