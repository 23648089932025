import { React, Component } from "react";
import { Carousel, Container } from "react-bootstrap";
import "./politica_cookies.css";

const public_folder = process.env.PUBLIC_URL;

class PoliticaCookies extends Component {
  render() {
    return (
      <div id="politica_cookies">
        <Container>
          <h1>POLÍTICA DE COOKIES</h1>
          Esta Política de Cookies é parte integrante da Política de Privacidade
          da Alfasigma disponível neste site. Os sites daAlfasigma Portugal,
          recolhem informações que podem dizer respeito ao utilizador, às suas
          preferências, ou ao dispositivo de acesso à Internet (computador,
          tablet ou telemóvel) e são utilizados principalmente para adaptar o
          funcionamento do site às expectativas do utilizador, oferecendo uma
          melhor experiência de navegação.
          <h4>O que são Cookies</h4>
          <p>
            Os cookies consistem em partes de código instaladas no interior do
            navegador de internet (browser) que podem ser armazenadas no seu
            computador e que ajudam o proprietário do site a prestar o serviço
            de acordo com os fins descritos.
          </p>
          <p>
            Graças aos Cookies, o site armazena algumas informações que são
            depois retransmitidas quando o utilizador regressa ao site ou navega
            de uma página para outra. Os cookies são também normalmente
            utilizados para armazenar informações anónimas relativas à navegação
            da página (ligações de entrada e saída, tempo de permanência),
            monitorizar e analisar o desempenho, funcionamento e eficácia do
            Sítio, a fim de o melhorar e otimizar.
          </p>
          <p>
            Alguns dos fins para os quais os cookies são instalados podem também
            requerer o seu consentimento.
          </p>
          <h4>Lista de Cookies utilizados por este Website</h4>
          <h4>Cookies estritamente necessários</h4>
          <p>
            Alguns cookies são essenciais para aceder a áreas específicas do
            nosso site. Permitem a navegação no site e a utilização das suas
            aplicações. Sem estes cookies, os serviços que o exijam não podem
            ser prestados.
          </p>
          <img src={public_folder + "/images/tabela_1.png"} />
          <h4>Cookies analíticos</h4>
          <p>
            Utilizamos estes cookies para analisar a forma como os utilizadores
            usam o site e monitorar a performance deste. Isto permite-nos
            fornecer uma experiência de alta qualidade ao personalizar a nossa
            oferta e rapidamente identificar e corrigir quaisquer problemas que
            surjam. Por exemplo, usamos cookies de desempenho para saber quais
            as páginas mais populares, qual o método de ligação entre páginas
            que é mais eficaz, ou para determinar a razão de algumas páginas
            estarem a receber mensagens de erro. Baseado na utilização do site,
            podemos também utilizar estes cookies para destacar artigos ou
            serviços do site que pensamos ser do interesse dos utilizadores.
            Estes cookies são utilizados apenas para efeitos de criação e
            análise estatística, sem nunca recolher informação de carácter
            pessoal.
          </p>
          <img src={public_folder + "/images/tabela_2.png"} />
        </Container>
      </div>
    );
  }
}

export default PoliticaCookies;
