import React from "react";
import { Component } from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./header.css";

//destructuring pathname from location
let { pathname } = window.location.pathname;
const public_folder = process.env.PUBLIC_URL;

class Header extends Component {
  render() {
    return (
      <header>
        <div id="top-header">
          <span>Este website contém publicidade</span>
        </div>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img
                style={{ maxWidth: "250px" }}
                src={public_folder + "/images/logo.png"}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {console.log()}
                <Nav.Link
                  href="/o-esofago"
                  className={
                    window.location.pathname === "/o-esofago" ? "active" : ""
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  O ESÓFAGO
                </Nav.Link>
                <Nav.Link
                  href="/refluxo-gastroesofagico"
                  className={
                    window.location.pathname === "/refluxo-gastroesofagico"
                      ? "active"
                      : ""
                  }
                >
                  REFLUXO GASTROESOFÁGICO
                </Nav.Link>
                <Nav.Link
                  href="/o-que-e-esoxx"
                  className={
                    window.location.pathname === "/o-que-e-esoxx"
                      ? "active"
                      : ""
                  }
                >
                  O QUE É <br />{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    ESOXX<sup>®</sup> ONE?
                  </span>
                </Nav.Link>
                <Nav.Link
                  href="/conselhos-praticos"
                  className={
                    window.location.pathname === "/conselhos-praticos"
                      ? "active"
                      : ""
                  }
                >
                  CONSELHOS PRÁTICOS
                </Nav.Link>
                <Nav.Link
                  className="external"
                  href="https://pt.alfasigma.com/areas-terapeuticas/farmacovigilancia/"
                  target="_blank"
                >
                  REPORTAR UM EFEITO ADVERSO
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Navbar.Brand href="https://pt.alfasigma.com/" target="_blank">
              <img
                style={{ maxWidth: "150px" }}
                src={public_folder + "/images/alfasigma_header.png"}
              />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
