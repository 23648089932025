import { React, Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./esofago.css";

const public_folder = process.env.PUBLIC_URL;

class Esofago extends Component {
  render() {
    return (
      <div
        id="esofago"
        style={{
          backgroundImage: `url(${public_folder}/images/esofago_bg.png)`,
        }}
      >
        <Container>
          <Row>
            <Col lg={5}>
              <img src={public_folder + "images/esofago_graph.png"} alt="" />
            </Col>
            <Col lg={7}>
              <h1>
                O ESÓFAGO
                <sup>
                  <small>1</small>
                </sup>
              </h1>
              <p>
                O esófago é um tubo muscular que liga a boca ao estômago. Tem
                cerca de um palmo de comprimento (20 cm), é revestido
                internamente por uma camada chamada mucosa (revestimento
                interno), e localiza-se atrás da traqueia e do coração e à
                frente da coluna vertebral.
              </p>

              <p>
                O esfíncter esofágico superior (EES) é um feixe de músculos na
                parte superior do esófago, que são usados quando se respira,
                come, arrota e vomita. Eles impedem que alimentos e secreções
                entrem pela traqueia.
              </p>

              <p>
                O esfíncter esofágico inferior (EEI) é um feixe de músculos na
                extremidade inferior do esófago, onde se encontra o estômago.
                Quando o EEI está encerrado, impede que o conteúdo ácido do
                estômago retorne para trás. Os músculos do EEI não estão sob
                controlo voluntário. O EEI é uma válvula que se abre para deixar
                passar os alimentos e evitar que eles passem de novo para o
                esófago. O seu mau funcionamento pode levar a refluxo ou seja a
                passagem de alimentos ou ácido do estômago para o esófago. Cerca
                de 3 milhões de pessoas já experimentaram refluxo.
              </p>
              <p>
                <small>Fonte: Adaptado de 1.</small>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Esofago;
